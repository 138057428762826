var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.showReserved)?_c('div',[_c('ConsumptionGauge',{attrs:{"capacity":_vm.fpgaCapacity,"used":_vm.fpgaAllocatable,"resource-name":_vm.resourceName},scopedSlots:_vm._u([{key:"title",fn:function(ref){
var amountTemplateValues = ref.amountTemplateValues;
var formattedPercentage = ref.formattedPercentage;
return [_c('span',[_vm._v("\n          "+_vm._s(_vm.t('clusterIndexPage.hardwareResourceGauge.reserved'))+"\n        ")]),_vm._v(" "),_c('span',[_vm._v("\n          "+_vm._s(_vm.t('node.detail.glance.consumptionGauge.amount', amountTemplateValues))+"\n          "),_c('span',{staticClass:"ml-10 percentage"},[_vm._v("/ "+_vm._s(formattedPercentage)+"\n          ")])])]}}],null,false,3338554630)})],1):_vm._e(),_vm._v(" "),_c('ConsumptionGauge',{class:{
      'mt-10': _vm.showReserved,
    },attrs:{"capacity":_vm.fpgaCapacity,"used":_vm.fpgaUsage},scopedSlots:_vm._u([{key:"title",fn:function(ref){
    var amountTemplateValues = ref.amountTemplateValues;
    var formattedPercentage = ref.formattedPercentage;
return [_c('span',[_vm._v("\n        "+_vm._s(_vm.t('clusterIndexPage.hardwareResourceGauge.used'))+"\n      ")]),_vm._v(" "),_c('span',[_vm._v("\n        "+_vm._s(_vm.t('node.detail.glance.consumptionGauge.amount', amountTemplateValues))+"\n        "),_c('span',{staticClass:"ml-10 percentage"},[_vm._v("/ "+_vm._s(formattedPercentage)+"\n        ")])])]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }