import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _7439eb97 = () => interopDefault(import('../shell/pages/about.vue' /* webpackChunkName: "shell/pages/about" */))
const _076deefa = () => interopDefault(import('../shell/pages/account/index.vue' /* webpackChunkName: "shell/pages/account/index" */))
const _e97511a0 = () => interopDefault(import('../shell/pages/c/index.vue' /* webpackChunkName: "shell/pages/c/index" */))
const _4170ade2 = () => interopDefault(import('../shell/pages/clusters/index.vue' /* webpackChunkName: "shell/pages/clusters/index" */))
const _725d660d = () => interopDefault(import('../shell/pages/diagnostic.vue' /* webpackChunkName: "shell/pages/diagnostic" */))
const _24fa92a0 = () => interopDefault(import('../shell/pages/fail-whale.vue' /* webpackChunkName: "shell/pages/fail-whale" */))
const _129235c5 = () => interopDefault(import('../shell/pages/home.vue' /* webpackChunkName: "shell/pages/home" */))
const _f7f86cac = () => interopDefault(import('../shell/pages/plugins.vue' /* webpackChunkName: "shell/pages/plugins" */))
const _77aa213a = () => interopDefault(import('../shell/pages/prefs.vue' /* webpackChunkName: "shell/pages/prefs" */))
const _ed507bc8 = () => interopDefault(import('../shell/pages/support/index.vue' /* webpackChunkName: "shell/pages/support/index" */))
const _387741d4 = () => interopDefault(import('../shell/pages/account/create-key.vue' /* webpackChunkName: "shell/pages/account/create-key" */))
const _0efd0db0 = () => interopDefault(import('../shell/pages/auth/login.vue' /* webpackChunkName: "shell/pages/auth/login" */))
const _64beb45b = () => interopDefault(import('../shell/pages/auth/logout.vue' /* webpackChunkName: "shell/pages/auth/logout" */))
const _8847cb88 = () => interopDefault(import('../shell/pages/auth/setup.vue' /* webpackChunkName: "shell/pages/auth/setup" */))
const _44e617ca = () => interopDefault(import('../shell/pages/auth/verify.vue' /* webpackChunkName: "shell/pages/auth/verify" */))
const _344f4d16 = () => interopDefault(import('../shell/pages/docs/toc.js' /* webpackChunkName: "shell/pages/docs/toc" */))
const _2a12282c = () => interopDefault(import('../shell/pages/rio/mesh.vue' /* webpackChunkName: "shell/pages/rio/mesh" */))
const _c36047e0 = () => interopDefault(import('../shell/pages/c/_cluster/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/index" */))
const _1d146ed7 = () => interopDefault(import('../shell/pages/docs/_doc.vue' /* webpackChunkName: "shell/pages/docs/_doc" */))
const _d2b1adb2 = () => interopDefault(import('../shell/pages/c/_cluster/apps/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/index" */))
const _0fad9e46 = () => interopDefault(import('../shell/pages/c/_cluster/auth/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/index" */))
const _11b8d1f7 = () => interopDefault(import('../shell/pages/c/_cluster/backup/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/backup/index" */))
const _33a1fb64 = () => interopDefault(import('../shell/pages/c/_cluster/cis/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/cis/index" */))
const _17aefe90 = () => interopDefault(import('../shell/pages/c/_cluster/ecm/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/ecm/index" */))
const _0d320498 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/index" */))
const _6dcd800f = () => interopDefault(import('../shell/pages/c/_cluster/fleet/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/fleet/index" */))
const _004f04f2 = () => interopDefault(import('../shell/pages/c/_cluster/gatekeeper/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/gatekeeper/index" */))
const _5eb9d0d2 = () => interopDefault(import('../shell/pages/c/_cluster/harvester/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/harvester/index" */))
const _2c7dce6c = () => interopDefault(import('../shell/pages/c/_cluster/harvesterManager/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/harvesterManager/index" */))
const _4f26891e = () => interopDefault(import('../shell/pages/c/_cluster/istio/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/istio/index" */))
const _30beb33e = () => interopDefault(import('../shell/pages/c/_cluster/legacy/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/index" */))
const _55e5fb60 = () => interopDefault(import('../shell/pages/c/_cluster/logging/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/logging/index" */))
const _16d79374 = () => interopDefault(import('../shell/pages/c/_cluster/longhorn/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/longhorn/index" */))
const _841c93a4 = () => interopDefault(import('../shell/pages/c/_cluster/manager/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/index" */))
const _1884cc86 = () => interopDefault(import('../shell/pages/c/_cluster/mcapps/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/mcapps/index" */))
const _d1189806 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/index" */))
const _540b3f3c = () => interopDefault(import('../shell/pages/c/_cluster/neuvector/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/neuvector/index" */))
const _9e1c56d0 = () => interopDefault(import('../shell/pages/c/_cluster/settings/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/index" */))
const _38a3bfd3 = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/index" */))
const _1a13cbec = () => interopDefault(import('../shell/pages/c/_cluster/auth/config/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/config/index" */))
const _156fcc6a = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/index" */))
const _636b5f43 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/ConfigBadge.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/ConfigBadge" */))
const _03f535f7 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/EventsTable.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/EventsTable" */))
const _aa85da84 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/explorer-utils.js' /* webpackChunkName: "shell/pages/c/_cluster/explorer/explorer-utils" */))
const _2317d200 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/tools/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/tools/index" */))
const _fddac160 = () => interopDefault(import('../shell/pages/c/_cluster/fleet/GitRepoGraphConfig.js' /* webpackChunkName: "shell/pages/c/_cluster/fleet/GitRepoGraphConfig" */))
const _08451859 = () => interopDefault(import('../shell/pages/c/_cluster/gatekeeper/constraints/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/gatekeeper/constraints/index" */))
const _47a498d8 = () => interopDefault(import('../shell/pages/c/_cluster/harvester/airgapupgrade/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/harvester/airgapupgrade/index" */))
const _57eda857 = () => interopDefault(import('../shell/pages/c/_cluster/harvester/support/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/harvester/support/index" */))
const _d6cb3870 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/index" */))
const _36a9ff6a = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/index" */))
const _97c7278a = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/index" */))
const _6f298a88 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/index" */))
const _1afd865b = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/index" */))
const _8bd986e6 = () => interopDefault(import('../shell/pages/c/_cluster/settings/banners.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/banners" */))
const _9bb2d666 = () => interopDefault(import('../shell/pages/c/_cluster/settings/brand.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/brand" */))
const _01a7fb14 = () => interopDefault(import('../shell/pages/c/_cluster/settings/performance.vue' /* webpackChunkName: "shell/pages/c/_cluster/settings/performance" */))
const _71f3ee42 = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/chart.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/chart" */))
const _0d5779dc = () => interopDefault(import('../shell/pages/c/_cluster/apps/charts/install.vue' /* webpackChunkName: "shell/pages/c/_cluster/apps/charts/install" */))
const _e39a533a = () => interopDefault(import('../shell/pages/c/_cluster/auth/group.principal/assign-edit.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/group.principal/assign-edit" */))
const _68d562f2 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/pipelines.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/pipelines" */))
const _4f2d585a = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/create" */))
const _c487dd94 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/create" */))
const _6c678fc3 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/create" */))
const _5322e291 = () => interopDefault(import('../shell/pages/c/_cluster/explorer/tools/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/explorer/tools/pages/_page" */))
const _047716f2 = () => interopDefault(import('../shell/pages/c/_cluster/auth/config/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/config/_id" */))
const _51cad84f = () => interopDefault(import('../shell/pages/c/_cluster/legacy/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/pages/_page" */))
const _0548af38 = () => interopDefault(import('../shell/pages/c/_cluster/legacy/project/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/legacy/project/_page" */))
const _6b029973 = () => interopDefault(import('../shell/pages/c/_cluster/manager/cloudCredential/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/cloudCredential/_id" */))
const _510be73f = () => interopDefault(import('../shell/pages/c/_cluster/manager/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/manager/pages/_page" */))
const _75bca38e = () => interopDefault(import('../shell/pages/c/_cluster/mcapps/pages/_page.vue' /* webpackChunkName: "shell/pages/c/_cluster/mcapps/pages/_page" */))
const _a4e5eb0e = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/index" */))
const _754138fa = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/route-receiver/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/route-receiver/_id" */))
const _dcf82516 = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/_resource/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/_resource/create" */))
const _c3619502 = () => interopDefault(import('../shell/pages/c/_cluster/harvester/console/_uid/serial.vue' /* webpackChunkName: "shell/pages/c/_cluster/harvester/console/_uid/serial" */))
const _405e2bb0 = () => interopDefault(import('../shell/pages/c/_cluster/harvester/console/_uid/vnc.vue' /* webpackChunkName: "shell/pages/c/_cluster/harvester/console/_uid/vnc" */))
const _124bf698 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/receiver.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/alertmanagerconfig/_alertmanagerconfigid/receiver" */))
const _2d7d3311 = () => interopDefault(import('../shell/pages/c/_cluster/auth/roles/_resource/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/auth/roles/_resource/_id" */))
const _223481c1 = () => interopDefault(import('../shell/pages/c/_cluster/monitoring/monitor/_namespace/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/monitoring/monitor/_namespace/_id" */))
const _6b1b5512 = () => interopDefault(import('../shell/pages/c/_cluster/navlinks/_group.vue' /* webpackChunkName: "shell/pages/c/_cluster/navlinks/_group" */))
const _4a6b8036 = () => interopDefault(import('../shell/pages/c/_cluster/_product/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/index" */))
const _43115122 = () => interopDefault(import('../shell/pages/c/_cluster/_product/members/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/members/index" */))
const _2a6f2475 = () => interopDefault(import('../shell/pages/c/_cluster/_product/namespaces.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/namespaces" */))
const _0fa7aa6f = () => interopDefault(import('../shell/pages/c/_cluster/_product/projectsnamespaces.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/projectsnamespaces" */))
const _6faa7803 = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/index.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/index" */))
const _a54e59ca = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/create.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/create" */))
const _af26c1aa = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/_id" */))
const _0eb78ea6 = () => interopDefault(import('../shell/pages/c/_cluster/_product/_resource/_namespace/_id.vue' /* webpackChunkName: "shell/pages/c/_cluster/_product/_resource/_namespace/_id" */))
const _d81c7348 = () => interopDefault(import('../shell/pages/index.vue' /* webpackChunkName: "shell/pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/dashboard/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/about",
    component: _7439eb97,
    name: "about"
  }, {
    path: "/account",
    component: _076deefa,
    name: "account"
  }, {
    path: "/c",
    component: _e97511a0,
    name: "c"
  }, {
    path: "/clusters",
    component: _4170ade2,
    name: "clusters"
  }, {
    path: "/diagnostic",
    component: _725d660d,
    name: "diagnostic"
  }, {
    path: "/fail-whale",
    component: _24fa92a0,
    name: "fail-whale"
  }, {
    path: "/home",
    component: _129235c5,
    name: "home"
  }, {
    path: "/plugins",
    component: _f7f86cac,
    name: "plugins"
  }, {
    path: "/prefs",
    component: _77aa213a,
    name: "prefs"
  }, {
    path: "/support",
    component: _ed507bc8,
    name: "support"
  }, {
    path: "/account/create-key",
    component: _387741d4,
    name: "account-create-key"
  }, {
    path: "/auth/login",
    component: _0efd0db0,
    name: "auth-login"
  }, {
    path: "/auth/logout",
    component: _64beb45b,
    name: "auth-logout"
  }, {
    path: "/auth/setup",
    component: _8847cb88,
    name: "auth-setup"
  }, {
    path: "/auth/verify",
    component: _44e617ca,
    name: "auth-verify"
  }, {
    path: "/docs/toc",
    component: _344f4d16,
    name: "docs-toc"
  }, {
    path: "/rio/mesh",
    component: _2a12282c,
    name: "rio-mesh"
  }, {
    path: "/c/:cluster",
    component: _c36047e0,
    name: "c-cluster"
  }, {
    path: "/docs/:doc?",
    component: _1d146ed7,
    name: "docs-doc"
  }, {
    path: "/c/:cluster/apps",
    component: _d2b1adb2,
    name: "c-cluster-apps"
  }, {
    path: "/c/:cluster/auth",
    component: _0fad9e46,
    name: "c-cluster-auth"
  }, {
    path: "/c/:cluster/backup",
    component: _11b8d1f7,
    name: "c-cluster-backup"
  }, {
    path: "/c/:cluster/cis",
    component: _33a1fb64,
    name: "c-cluster-cis"
  }, {
    path: "/c/:cluster/ecm",
    component: _17aefe90,
    name: "c-cluster-ecm"
  }, {
    path: "/c/:cluster/explorer",
    component: _0d320498,
    name: "c-cluster-explorer"
  }, {
    path: "/c/:cluster/fleet",
    component: _6dcd800f,
    name: "c-cluster-fleet"
  }, {
    path: "/c/:cluster/gatekeeper",
    component: _004f04f2,
    name: "c-cluster-gatekeeper"
  }, {
    path: "/c/:cluster/harvester",
    component: _5eb9d0d2,
    name: "c-cluster-harvester"
  }, {
    path: "/c/:cluster/harvesterManager",
    component: _2c7dce6c,
    name: "c-cluster-harvesterManager"
  }, {
    path: "/c/:cluster/istio",
    component: _4f26891e,
    name: "c-cluster-istio"
  }, {
    path: "/c/:cluster/legacy",
    component: _30beb33e,
    name: "c-cluster-legacy"
  }, {
    path: "/c/:cluster/logging",
    component: _55e5fb60,
    name: "c-cluster-logging"
  }, {
    path: "/c/:cluster/longhorn",
    component: _16d79374,
    name: "c-cluster-longhorn"
  }, {
    path: "/c/:cluster/manager",
    component: _841c93a4,
    name: "c-cluster-manager"
  }, {
    path: "/c/:cluster/mcapps",
    component: _1884cc86,
    name: "c-cluster-mcapps"
  }, {
    path: "/c/:cluster/monitoring",
    component: _d1189806,
    name: "c-cluster-monitoring"
  }, {
    path: "/c/:cluster/neuvector",
    component: _540b3f3c,
    name: "c-cluster-neuvector"
  }, {
    path: "/c/:cluster/settings",
    component: _9e1c56d0,
    name: "c-cluster-settings"
  }, {
    path: "/c/:cluster/apps/charts",
    component: _38a3bfd3,
    name: "c-cluster-apps-charts"
  }, {
    path: "/c/:cluster/auth/config",
    component: _1a13cbec,
    name: "c-cluster-auth-config"
  }, {
    path: "/c/:cluster/auth/roles",
    component: _156fcc6a,
    name: "c-cluster-auth-roles"
  }, {
    path: "/c/:cluster/explorer/ConfigBadge",
    component: _636b5f43,
    name: "c-cluster-explorer-ConfigBadge"
  }, {
    path: "/c/:cluster/explorer/EventsTable",
    component: _03f535f7,
    name: "c-cluster-explorer-EventsTable"
  }, {
    path: "/c/:cluster/explorer/explorer-utils",
    component: _aa85da84,
    name: "c-cluster-explorer-explorer-utils"
  }, {
    path: "/c/:cluster/explorer/tools",
    component: _2317d200,
    name: "c-cluster-explorer-tools"
  }, {
    path: "/c/:cluster/fleet/GitRepoGraphConfig",
    component: _fddac160,
    name: "c-cluster-fleet-GitRepoGraphConfig"
  }, {
    path: "/c/:cluster/gatekeeper/constraints",
    component: _08451859,
    name: "c-cluster-gatekeeper-constraints"
  }, {
    path: "/c/:cluster/harvester/airgapupgrade",
    component: _47a498d8,
    name: "c-cluster-harvester-airgapupgrade"
  }, {
    path: "/c/:cluster/harvester/support",
    component: _57eda857,
    name: "c-cluster-harvester-support"
  }, {
    path: "/c/:cluster/legacy/project",
    component: _d6cb3870,
    name: "c-cluster-legacy-project"
  }, {
    path: "/c/:cluster/manager/cloudCredential",
    component: _36a9ff6a,
    name: "c-cluster-manager-cloudCredential"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig",
    component: _97c7278a,
    name: "c-cluster-monitoring-alertmanagerconfig"
  }, {
    path: "/c/:cluster/monitoring/monitor",
    component: _6f298a88,
    name: "c-cluster-monitoring-monitor"
  }, {
    path: "/c/:cluster/monitoring/route-receiver",
    component: _1afd865b,
    name: "c-cluster-monitoring-route-receiver"
  }, {
    path: "/c/:cluster/settings/banners",
    component: _8bd986e6,
    name: "c-cluster-settings-banners"
  }, {
    path: "/c/:cluster/settings/brand",
    component: _9bb2d666,
    name: "c-cluster-settings-brand"
  }, {
    path: "/c/:cluster/settings/performance",
    component: _01a7fb14,
    name: "c-cluster-settings-performance"
  }, {
    path: "/c/:cluster/apps/charts/chart",
    component: _71f3ee42,
    name: "c-cluster-apps-charts-chart"
  }, {
    path: "/c/:cluster/apps/charts/install",
    component: _0d5779dc,
    name: "c-cluster-apps-charts-install"
  }, {
    path: "/c/:cluster/auth/group.principal/assign-edit",
    component: _e39a533a,
    name: "c-cluster-auth-group.principal-assign-edit"
  }, {
    path: "/c/:cluster/legacy/project/pipelines",
    component: _68d562f2,
    name: "c-cluster-legacy-project-pipelines"
  }, {
    path: "/c/:cluster/manager/cloudCredential/create",
    component: _4f2d585a,
    name: "c-cluster-manager-cloudCredential-create"
  }, {
    path: "/c/:cluster/monitoring/monitor/create",
    component: _c487dd94,
    name: "c-cluster-monitoring-monitor-create"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/create",
    component: _6c678fc3,
    name: "c-cluster-monitoring-route-receiver-create"
  }, {
    path: "/c/:cluster/explorer/tools/pages/:page?",
    component: _5322e291,
    name: "c-cluster-explorer-tools-pages-page"
  }, {
    path: "/c/:cluster/auth/config/:id",
    component: _047716f2,
    name: "c-cluster-auth-config-id"
  }, {
    path: "/c/:cluster/legacy/pages/:page?",
    component: _51cad84f,
    name: "c-cluster-legacy-pages-page"
  }, {
    path: "/c/:cluster/legacy/project/:page",
    component: _0548af38,
    name: "c-cluster-legacy-project-page"
  }, {
    path: "/c/:cluster/manager/cloudCredential/:id",
    component: _6b029973,
    name: "c-cluster-manager-cloudCredential-id"
  }, {
    path: "/c/:cluster/manager/pages/:page?",
    component: _510be73f,
    name: "c-cluster-manager-pages-page"
  }, {
    path: "/c/:cluster/mcapps/pages/:page?",
    component: _75bca38e,
    name: "c-cluster-mcapps-pages-page"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig/:alertmanagerconfigid",
    component: _a4e5eb0e,
    name: "c-cluster-monitoring-alertmanagerconfig-alertmanagerconfigid"
  }, {
    path: "/c/:cluster/monitoring/route-receiver/:id?",
    component: _754138fa,
    name: "c-cluster-monitoring-route-receiver-id"
  }, {
    path: "/c/:cluster/auth/roles/:resource/create",
    component: _dcf82516,
    name: "c-cluster-auth-roles-resource-create"
  }, {
    path: "/c/:cluster/harvester/console/:uid?/serial",
    component: _c3619502,
    name: "c-cluster-harvester-console-uid-serial"
  }, {
    path: "/c/:cluster/harvester/console/:uid?/vnc",
    component: _405e2bb0,
    name: "c-cluster-harvester-console-uid-vnc"
  }, {
    path: "/c/:cluster/monitoring/alertmanagerconfig/:alertmanagerconfigid/receiver",
    component: _124bf698,
    name: "c-cluster-monitoring-alertmanagerconfig-alertmanagerconfigid-receiver"
  }, {
    path: "/c/:cluster/auth/roles/:resource/:id?",
    component: _2d7d3311,
    name: "c-cluster-auth-roles-resource-id"
  }, {
    path: "/c/:cluster/monitoring/monitor/:namespace/:id?",
    component: _223481c1,
    name: "c-cluster-monitoring-monitor-namespace-id"
  }, {
    path: "/c/:cluster/navlinks/:group?",
    component: _6b1b5512,
    name: "c-cluster-navlinks-group"
  }, {
    path: "/c/:cluster/:product",
    component: _4a6b8036,
    name: "c-cluster-product"
  }, {
    path: "/c/:cluster/:product/members",
    component: _43115122,
    name: "c-cluster-product-members"
  }, {
    path: "/c/:cluster/:product/namespaces",
    component: _2a6f2475,
    name: "c-cluster-product-namespaces"
  }, {
    path: "/c/:cluster/:product/projectsnamespaces",
    component: _0fa7aa6f,
    name: "c-cluster-product-projectsnamespaces"
  }, {
    path: "/c/:cluster/:product/:resource",
    component: _6faa7803,
    name: "c-cluster-product-resource"
  }, {
    path: "/c/:cluster/:product/:resource/create",
    component: _a54e59ca,
    name: "c-cluster-product-resource-create"
  }, {
    path: "/c/:cluster/:product/:resource/:id",
    component: _af26c1aa,
    name: "c-cluster-product-resource-id"
  }, {
    path: "/c/:cluster/:product/:resource/:namespace/:id?",
    component: _0eb78ea6,
    name: "c-cluster-product-resource-namespace-id"
  }, {
    path: "/",
    component: _d81c7348,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
